
import { defineComponent, inject, ref } from 'vue'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'


export default defineComponent({
    components: { Field, Form, ErrorMessage },
    setup() {
        const configurationStore: any = inject('configurationStore')

        const generalError = ref(false)
        const schema = yup.object().shape({
            password: yup.string().required(i18n.global.t('FIELD_REQUIRED')),
            newPassword: yup
                .string()
                .min(6, i18n.global.t('WRONG_PASSWORD_FORMAT'))
                .required(i18n.global.t('FIELD_REQUIRED')),
            confirmPassword: yup
                .string()
                .required(i18n.global.t('FIELD_REQUIRED'))
                .oneOf(
                    [yup.ref('newPassword'), null],
                    i18n.global.t('PASSWORD_MATCH_ERROR')
                ),
        })
        const password = ref('')
        const newPassword = ref('')
        const confirmPassword = ref('')
        const changePassword = async () => {
            const data = {
                oldPassword: password.value,
                newPassword: newPassword.value,
            }
            const success = configurationStore.ChangePassword(data)
            if (!success) {
                generalError.value = true
                return
            }
            Swal.fire({
                icon: 'success',
                title: i18n.global.t('PASSWORD_UPDATED'),
                text: i18n.global.t('USE_NEW_PASSWORD'),
            })
        }
        return {
            password,
            newPassword,
            confirmPassword,
            changePassword,
            generalError,
            configurationStore,
            schema,
        }
    },
})


  
  import Swal from 'sweetalert2'
  import SwitchInput from "../../../commonvue/src/components/SwitchInput/SwitchInput.vue"
  import i18n from '@/i18n'
  import { 
    defineComponent,
    inject,
    ref
  } from 'vue'

  export default defineComponent({
    components: {
      SwitchInput
    },
    data() {
      return {
        notifTypes: [
          { value: 'push', label: i18n.global.t('NOTIFICATION_TYPE_MOBILE') },
          { value: 'email', label: i18n.global.t('NOTIFICATION_TYPE_EMAIL') }
        ]
      }
    },
    setup() {
      const configurationStore: any = inject('configurationStore');
      const authStore: any = inject('authState');

      const notifNewProject = ref(authStore.state.userData.notifNewProject);
      const notifPush = ref(authStore.state.userData.notifPush);

      const notificationsEnabled = ref(false);
      const selectedNotifType = ref('push');

      if (notifNewProject.value || notifPush.value) {
        notificationsEnabled.value = true;
      } else {
        notificationsEnabled.value = false;
      }

      if (notifNewProject.value) {
        selectedNotifType.value = 'email';
      } else if (notifPush.value) {
        selectedNotifType.value = 'push';
      }
      
      const updateNotifications = () => {
        const data = {
          notifByEmail: notifNewProject.value,
          notifByPush: notifPush.value
        }
        if (notificationsEnabled.value) {
          if (selectedNotifType.value == 'email') {
            data.notifByEmail = true;
            data.notifByPush = false;
          } else if(selectedNotifType.value == 'push') {
            data.notifByEmail = false;
            data.notifByPush = true;
          }
        } else {
          data.notifByEmail = false;
          data.notifByPush = false;
        }

        const success = configurationStore.UpdateNotifications(data)
        if (!success) {
          Swal.fire({
            icon: 'error',
            title: i18n.global.t('Error_Try_Again'),
            text: i18n.global.t('tester_check_email'),
          })
          return
        } else {
          Swal.fire({
            icon: 'success',
            title: i18n.global.t('notification_new_value_save'),
            text: i18n.global.t('notification_new_value_text'),
          })
        }
      }

      return {
        notificationsEnabled,
        selectedNotifType,
        updateNotifications,
        authStore
      }
    }
  })


import {
    computed,
    ComputedRef,
    defineComponent,
    inject,
    onBeforeMount,
    ref,
} from 'vue'
import { useField } from 'vee-validate'
import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'

import Swal from 'sweetalert2'

export default defineComponent({
    setup() {
        const authStore: any = inject('authState')
        const configurationStore: any = inject('configurationStore')
        const validatePhoneTooltipMessage = ref('')
        const setValidatePhoneTooltipMessage = () => {
            if (authStore.state.userData.mobileNum === null) {
                validatePhoneTooltipMessage.value =
                i18n.global.t('ADD_PHONE_TO_VALIDATE')
                return
            }
            if (
                authStore.state.userData.mobileNum !== null &&
                authStore.state.userData.mobileValidated
            ) {
                validatePhoneTooltipMessage.value = i18n.global.t('PHONE_ALREADY_VALIDATED')
                return
            }
        }
        const showValidatePhoneToolTipMessage = ref(false)
        const setShowValidatePhoneToolTipMessage = (value: boolean) => {
            showValidatePhoneToolTipMessage.value = value
        }
        const { errorMessage: errorPhone, value: phone } = useField<string>(
            'phone',
            yup
                .string()
                .required(i18n.global.t('FIELD_REQUIRED'))
                .matches(/^[0-9]+$/, i18n.global.t('WRONG_PHONE_FROMAT'))
                .min(9, i18n.global.t('WRONG_PHONE_FROMAT'))
                .max(9, i18n.global.t('WRONG_PHONE_FROMAT'))
        )
        const {
            errorMessage: errorPhoneValidationCode,
            value: phoneValidationCode,
        } = useField<string>(
            'phoneValidationCode',
            yup
                .string()
                .required(i18n.global.t('FIELD_REQUIRED'))
                .matches(/^[0-9]+$/, i18n.global.t('WRONG_VALIDATIONCODE_FORMAT'))
        )
        const phoneFormValid: ComputedRef<boolean> = computed(
            (): boolean => !!phone.value && !errorPhone.value
        )
        const samePhone: ComputedRef<boolean> = computed(
            (): boolean => authStore.state.userData.mobileNum == phone.value
        )
        const validationCodeFormValid: ComputedRef<boolean> = computed(
            (): boolean =>
                !!phoneValidationCode.value && !errorPhoneValidationCode.value
        )
        const updatePhoneNumber = async () => {
            if (!phoneFormValid) {
                return
            }
            const success = await configurationStore.newMobile({
                mobile: phone.value
            });
            if (success === true) {
                authStore.getUserDetails()
                Swal.fire({
                    icon: 'success',
                    title: i18n.global.t('tester_mobile_validated'),
                    text: i18n.global.t('tester_email_validate_mobile'),
                })
            } else if (success === 'registrationMobileFraud') {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('tester_validate_mobile_error')
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('Error_Try_Again'),
                    text: i18n.global.t('tester_mobile_validation_check'),
                })
            }
        }
        const showValidatePhoneNumberForm = async () => {
            setValidatePhoneTooltipMessage()
            if (
                authStore.state.userData.mobileNum == null ||
                authStore.state.userData.mobileValidated
            ) {
                setShowValidatePhoneToolTipMessage(true)
                setTimeout(() => {
                    setShowValidatePhoneToolTipMessage(false)
                }, 2000)
                return
            }
            const resendMobileConfirmation = async () => {
                const success =
                    await configurationStore.resendMobileConfirmation()
                if (success) {
                    Swal.fire({
                        icon: 'success',
                        title: i18n.global.t('tester_mobile_validation_resend'),
                        text: i18n.global.t('tester_email_validate_mobile'),
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: i18n.global.t('Error_Try_Again'),
                    })
                }
            }
            const container = document.createElement('div')
            container.innerText = i18n.global.t('tester_mobile_validation_resend2')

            const br = document.createElement('span')
            br.innerHTML = '<br/>'
            container.appendChild(br)

            const resendPhoneValidationCode = document.createElement('a')
            resendPhoneValidationCode.addEventListener(
                'click',
                resendMobileConfirmation
            )
            resendPhoneValidationCode.innerText = i18n.global.t('tester_mobile_validation_resend_action')
            resendPhoneValidationCode.style.cursor = 'pointer'
            container.appendChild(resendPhoneValidationCode)
            const { value: code } = await Swal.fire({
                title: i18n.global.t('tester_inser_validation_code'),
                html: container,
                input: 'text',
                inputLabel: i18n.global.t('tester_validation_code'),
                showCancelButton: true,
                backdrop: 'transparent',
                showClass: {
                    popup: 'animate__animated animate__bounceIn animate__fast',
                },
                hideClass: {
                    popup: 'animate__animated animate__bounceOut animate__fast',
                },
                inputValidator: (value) => {
                    if (value === '') {
                        return i18n.global.t('tester_required_input')
                    }
                    if (isNaN(Number(value))) {
                        return i18n.global.t('tester_required_number')
                    }
                    return null
                },
            })

            if (code) {
                validatePhoneNumber()
            }
        }
        const validatePhoneNumber = async () => {
            const success = await configurationStore.validateMobile(
                authStore.state.userData.identifier,
                { validationCode: phoneValidationCode.value }
            )
            if (success) {
                Swal.fire({
                    icon: 'success',
                    title: i18n.global.t('tester_mobile_validated_msg'),
                    text: i18n.global.t('tester_mobile_validated_msg2'),
                })
                setValidatePhoneTooltipMessage()
                return
            }
            Swal.fire({
                icon: 'error',
                title: i18n.global.t('tester_mobile_incorrect'),
                text: i18n.global.t('tester_mobile_not_valid'),
            })
        }
        onBeforeMount(async () => {
            if(authStore.state.userData.mobileNum){
                phone.value = await authStore.state.userData.mobileNum
            }
            setValidatePhoneTooltipMessage()
        })
        return {
            authStore,
            phone,
            errorPhone,
            phoneValidationCode,
            errorPhoneValidationCode,
            phoneFormValid,
            updatePhoneNumber,
            validatePhoneTooltipMessage,
            setValidatePhoneTooltipMessage,
            showValidatePhoneToolTipMessage,
            setShowValidatePhoneToolTipMessage,
            showValidatePhoneNumberForm,
            samePhone
        }
    },
})

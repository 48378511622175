
import Swal from 'sweetalert2'
import { useField } from 'vee-validate'
import {
    computed,
    ComputedRef,
    defineComponent,
    inject,
    onBeforeMount,
} from 'vue'
import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'


export default defineComponent({
    setup() {
        const configurationStore: any = inject('configurationStore')
        const authStore: any = inject('authState')
        const { errorMessage: errorEmail, value: email } = useField<string>(
            'alias',
            yup
                .string()
                .required(i18n.global.t('tester_required_email'))
                .email(i18n.global.t('tester_required_email_format'))
        )
        const emailFormValid: ComputedRef<boolean> = computed(
            (): boolean => !!email.value && !errorEmail.value
        )
        onBeforeMount(async () => {
            email.value = authStore.state.userData.email
        })
        const changeEmail = () => {
            if (!emailFormValid) {
                return
            }
            const data = {
                newemail: email.value,
            }
            const success = configurationStore.ChangeEmail(data)
            if (!success) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('Error_Try_Again'),
                    text: i18n.global.t('tester_check_email'),
                })
                return
            }
            Swal.fire({
                icon: 'success',
                title: i18n.global.t('tester_email_updated'),
                text: i18n.global.t('tester_email_validate'),
            })
        }
        const resendEmailConfirmation = async () => {
            const success = await configurationStore.resendEmailConfirmation()
            if (!success) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t(
                        'Error_Try_Again'
                    ),
                })
                return
            }
            Swal.fire({
                icon: 'success',
                title: i18n.global.t('Client_settings_settingscontroller_save'),
                text: i18n.global.t('Client_settings_settingscontroller_email'),
            })
        }
        return {
            email,
            errorEmail,
            emailFormValid,
            changeEmail,
            resendEmailConfirmation,
            authStore,
        }
    },
})
